import dh_header from '../elements/dh_header2.png'
import compass from "../elements/accent-compass.png"

function Header() {
  return (
    <div className="header" id='top'>
      <div className="logo_container">
        <img
          src={dh_header}
          alt="discovery house logo"
          className="header_logo"
        />
        <h1 className='marquee'>Sober Living Homes</h1>
      </div>
      <div className='marquee-container'>
        <div className='border-box'>
          <h2 className='marquee2'>Addiction</h2>
          {/* <img src={compass} alt="compass" className='accent' /> */}
          <h2 className='marquee2'>Mental Health</h2>
          {/* <img src={compass} alt="compass" className='accent' /> */}
          <h2 className='marquee2'>Homelessness</h2>
        </div>

      </div>
    </div>
  )
}

export default Header
