import './App.css'
import './Mobile.css'
import { Routes, Route } from 'react-router-dom'

import Header from './components/header'
import About from './pages/about'
import Admin from './pages/admin'
import Connect from './pages/contact'
import Donate from './pages/donate'
import Resident from './pages/resicency'
import Home from './pages/home'
import Links from './pages/links'
import Announcements from './pages/announcements'
import Footer from './components/footer'
import CRUD from './pages/crud'
import UpdateDelete from './pages/updateDelete'
import HomeTop from './components/homeTop'
import PageDown from './pages/page_down'

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<PageDown />} />
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/connect" element={<Connect />} /> */}
        {/* <Route path="/announcements" element={<Announcements />} /> */}
        {/* <Route path="/donate" element={<Donate />} /> */}
        {/* <Route path="/residency" element={<Resident />} /> */}
        {/* <Route path="/links" element={<Links />} /> */}
        {/* <Route path="/admin" element={<Admin />} /> */}
        {/* <Route path="/announcement-add-edit-delete" element={<CRUD />} /> */}
        {/* <Route path="/posts/:event_id" element={<UpdateDelete />} /> */}
      </Routes>
      {/* <HomeTop /> */}
      {/* <Footer /> */}
    </div>
  )
}

export default App
