function PageDown() {
  return (
    <div className="page">
      <div className="page_content_container">
        <h3 className="page_content_header">This page is currently being rennovated</h3>
        <p className="page_content_text">In the meantime, please contact us with any questions.</p>
        <p className="page_content_text">Phone: 712-336-5340</p>
      </div>
    </div>
  )
}

export default PageDown
