import Nav from '../components/nav'
import flourish from '../elements/flourish.png'
import woman from '../elements/woman.png'
import mission from '../elements/mission.png'
import historyIcon from '../elements/history.png'
import houseSLWest from '../elements/house-slwest.png'
import houseSLEast from '../elements/house-sleast.png'
import houseSpencer from '../elements/house-spencer.png'
import people from '../elements/people.png'
import headshotKW from '../elements/headshot-kw.png'
import headshotGC from '../elements/headshot-gc.png'
import headshotMM from '../elements/headshot-mm.png'
import headshotJS from '../elements/headshot-js.png'
import headshotNH from '../elements/headshot-nh.png'
import headshotJA from '../elements/headshot-ja.png'
import headshotBW from '../elements/headshotBW.png'

function About() {
  const exitAlert = () => {
    alert(
      'You are now leaving the Discovery House page and navigating to an external website.'
    )
  }
  return (
    <div className="page">
      <Nav />
      <h1 className="page_title">About Discovery House</h1>
      <div className="page_content_container">
        <img className="header_img" src={mission} />
        <h2 className="page_content_header">Vision</h2>
        <img className="flourish" src={flourish} />
        <p className="page_content_text">
          To create a safe, supportive, and empowering community that fosters
          personal growth, accountability, and a sense of purpose. To help
          residents achieve long-term sobriety, maintain healthy relationships,
          and successfully reintegrate into society. We believe that every
          individual has the potential to thrive in recovery and we are
          committed to providing the tools and resources necessary for them to
          do so.
        </p>
      </div>
      <div className="page_content_container">
        <img className="header_img" src={mission} />
        <h2 className="page_content_header">Mission</h2>
        <img className="flourish" src={flourish} />
        <p className="page_content_text">
          Discovery House provides a safe, supportive, Christian environment for
          homeless adults and those recovering from addictions. Residents
          receive counseling and the tools to begin to build a new foundation
          for a better life.
        </p>
      </div>
      <div className="page_content_container">
        <img className="header_img" src={historyIcon} />
        <h2 className="page_content_header">History</h2>
        <img className="flourish" src={flourish} />
        <p className="page_content_text">
          Founded in 1993, Discovery House, in Spirit Lake and Spencer, Iowa,
          provides sober-living for up to twelve months for men and women who
          are in recovery from alcoholism and other addictions or struggling
          with homelessness (being unhoused). They may or may not have completed
          a substance abuse treatment Program prior to admission. Discovery
          House offers case management to help residents set realistic goals for
          obtaining steady employment and position themselves for
          self-sufficiency upon leaving the program.
        </p>
        <p className="page_content_text">
          Phyllis Kempema, a recovered alcoholic, who wanted to help alcoholics,
          drug addicts, and unhoused individuals get back on their feet and
          overcome their addictions, started Discovery House as a sober homeless
          shelter with an initial capacity of up to 5 residents.
        </p>
        <p className="page_content_text">
          In 2023, Discovery House received a third location in Spencer. It was
          an exciting process that opens the doors to the future of recovery
          residences in northwest Iowa. Today, Discovery House is able to serve
          as many as 34 men and women between the three locations from Northwest
          Iowa, Southern Minnesota, and surrounding states. It is the only such
          facility in the area.
        </p>
        <p className="page_content_text">
          In addition to residential services, Discovery House also has the
          ability to do Substance Use Disorder evaluations, individual services,
          and group services for non-residents
        </p>
        <p className="page_content_text">
          Discovery House encourages people to apply from treatment, jail,
          prison, on pre-trial release, or straight off the street.
        </p>
      </div>
      <div className="page_content_container">
        <h2 className="page_content_header">Locations</h2>
        <img className="flourish" src={flourish} />
        <a
          href="https://www.google.com/maps/place/1411+Hill+Ave,+Spirit+Lake,+IA+51360/@43.4257053,-95.1044487,16z/data=!3m1!4b1!4m6!3m5!1s0x878cb45f83dfd95d:0xbde9b0db1bb64dd3!8m2!3d43.4257053!4d-95.1018684!16s%2Fg%2F11c1d5rt68?entry=ttu"
          onClick={exitAlert}
        >
          <h2 className="address-text-title">Spirit Lake Campus West</h2>
          <h3 className="address-text-subtitle">Women's House</h3>
          <img
            className="map"
            src={houseSLWest}
            alt="google map showing the location of Discovery House Spirit Lake"
          />
          <div className="address-text-container">
            <p className="address-text">1411 Hill Ave</p>
            <p className="address-text">Spirit Lake, IA 51360</p>
            <p className="address-text">712.336.5340</p>
          </div>
          <div className="gmaps-link">
            <em className="note">10 Residents / 1 Onsite Manager</em>
            <p className="link-prompt">
              Click the maps icon above to navigate with Google Maps
            </p>
          </div>
        </a>
        <a href="https://maps.app.goo.gl/SniUUiwAikZAcQPn9" onClick={exitAlert}>
          <h2 className="address-text-title">Spirit Lake Campus East</h2>
          <h3 className="address-text-subtitle">(Daniel House)</h3>
          <img
            className="map"
            src={houseSLEast}
            alt="google map showing the location of Discovery House Spirit Lake"
          />
          <div className="address-text-container">
            <p className="address-text">1408 Gary Ave</p>
            <p className="address-text">Spirit Lake, IA 51360</p>
            <p className="address-text">712.336.5340</p>
          </div>
          <div className="gmaps-link">
            <em className="note">13 Residents</em>
            <p className="link-prompt">
              Click the maps icon above to navigate with Google Maps
            </p>
          </div>
        </a>
        <a href="https://maps.app.goo.gl/edzeHjaWD6M8SMhD7" onClick={exitAlert}>
          <h2 className="address-text-title">Spencer Campus</h2>
          <img
            className="map"
            src={houseSpencer}
            alt="google map showing the location of Discovery House Spencer"
          />
          <div className="address-text-container">
            <p className="address-text">305 W 6th St</p>
            <p className="address-text">Spencer, IA 51301</p>
            <p className="address-text">712.580.5340</p>
          </div>
          <div className="gmaps-link">
            <em className="note">10 Residents (Co-ed)</em>
            <p className="link-prompt">
              Click the maps icon above to navigate with Google Maps
            </p>
          </div>
        </a>
      </div>
      <div className="page_content_container">
        <img className="header_img" src={people} />
        <h2 className="page_content_header">The People</h2>
        <h5 className="page_content_header">
          <em>Meet the people who make it happen</em>
        </h5>
        <img className="flourish" src={flourish} />
        <div className="board_member_container">
          <img className="profile-img" src={headshotJA} />
          <h4 className="board_member">
            Jessica Amendt ⎯ <em>Executive Program Director</em>
          </h4>
          <p className="page_content_text">
            Jessica received her Bachelor's Degree from Buena Vista University
            with a double major in Human Services and Psychology and a minor in
            Sociology. She later went on to receive her Master's Degree from
            Capella University in Counseling Studies with a certificate in
            Professional Counseling. Most recently, she earned her International
            Alcohol and Drug Counselor (IADC) Certification. Jessica is a
            Spencer native and has worked as a Social Worker in and around the
            surrounding communities for over 20 years. She began her career in
            the very building that became the Spencer Campus when she was the
            Clay County Coordinator for CAASA. From there she held a variety of
            positions within the area of children and family services, substance
            use disorder services, co-occurring support services, and served
            many years on crisis response teams working on crisis lines and
            mobile crisis units. She also loved her time as an adjunct professor
            for Iowa Lakes Community College and Buena Vista University. Jessica
            continues to reside in Spencer with her husband, John. She spends
            any cherished free time with their children Kylan (Allison), Shelby,
            Becka, Jayden, and their first grandchild, Xavier. Her hobbies
            include reading, crafting, and attending dirt-track racing
            (especially the Figure 8's)!
          </p>
          <p className="page_content_text">
            Jessica Amendt is the Executive Program Director overseeing all
            three locations. Nealey Powers is the Spirit Lake Campus West
            Manager and Brad Johnson is the Spirit Lake Campus East Manager.
            Beyond that, Discovery House is manned entirely by a team of amazing
            volunteers from around the communities we serve.
          </p>
        </div>
        <h2 className="staff_type">Discovery House Board</h2>
        <div className="board_member_container">
          <img className="profile-img" src={headshotKW} />
          <h4 className="board_member">
            Kurt Wiegers ⎯ <em>President</em>
          </h4>
          <p className="page_content_text">
            Kurt Wiegers has served as a board director since 2018, and as the
            treasurer he is responsible for budget oversite and general
            financial management. He has also been instrumental in supporting a
            growth strategy at Discovery House that has resulted in a 400%
            increase in residents served. With a career in financial services
            that spans over 30 years, he is the Senior Vice President and Chief
            Marketing Officer at Bank Midwest, a financial services company with
            locations throughout Iowa, Minnesota, and South Dakota. Kurt has a
            BS in Psychology from Illinois State University where he became
            interested in physiological psychology and the impact that brain
            health and nutrition have on behavior and addiction. He and his wife
            Julia live in Spirit Lake, IA and have two grown children. In his
            spare time, Kurt enjoys boating, fishing, and restoring classic
            cars.
          </p>
        </div>
        <div className="board_member_container">
          <img className="profile-img" src={headshotBW} />
          <h4 className="board_member">
            Beth Will ⎯ <em>Vice President</em>
          </h4>
          <p className="page_content_text">
            Community Services Sioux Rivers Mental Health Region Service
            Coordinator. Named 2023 Citizen of the Year by Spirit Lake Kiwanas.
          </p>
        </div>
        <div className="board_member_container">
          <img className="profile-img" src={headshotNH} />
          <h4 className="board_member">
            Pastor Nicholas Hanges ⎯ <em>Secretary</em>
          </h4>
          <p className="page_content_text">
            Lead Pastor at Foundation Church - Chairman of the Spencer
            Ministerial Association.
          </p>
        </div>
        <div className="board_member_container">
          <img className="profile-img" src={woman} />
          <h4 className="board_member">
            Tanya Thelan ⎯ <em>Board Member</em>
          </h4>
          <p className="page_content_text">
            Tanya is currently the housing director for Upper DesMoines Housing
            Opportunities.
          </p>
        </div>
        <div className="board_member_container">
          <img className="profile-img" src={headshotGC} />
          <h4 className="board_member">
            Gary Caviness ⎯ <em>Board Member</em>
          </h4>
          <p className="page_content_text">
            Retired Substance Abuse Counselor. Currently spends his time with
            his wife, Carol, and helps with programming and development at
            Discovery House. Gary provided supervision to the Director for her
            to receive her IADC and continues to be a valuable mentor.
          </p>
        </div>
        <div className="board_member_container">
          <img className="profile-img" src={headshotMM} />
          <h4 className="board_member">
            Mick McCleery ⎯ <em>Board Member</em>
          </h4>
          <p className="page_content_text">
            Discovery House Alumni - Born in London Egland on 1953, Mick lived
            mostly in Europe until he was 18 years old. He came to the states on
            his18th birthday permanently to go to college and play football.
            Mick retired from Deere &amp; Company as a Director. He had a career
            in Manfacturing Leadership at all levels and later in Corporate
            Supplier Development, teaching Key Suppliers Lean Manufacturing to
            improve their quality, delivery, and profitability. Graduated at
            Iowa in Engineering on 1979. Post Graduate degrees in Manufacturing
            and Employee Development. Married to Ruth Lynn McCleery and lives on
            Loon Lake in Minnesota.
          </p>
        </div>
        <div className="board_member_container">
          <img className="profile-img" src={headshotJS} />
          <h4 className="board_member">
            Judge John Sandy ⎯ <em>Board Member</em>
          </h4>
          <p className="page_content_text">
            Judge Sandy earned his undergraduate degree with honors from the
            University of St. Thomas in 2007. He attended the University of St.
            Thomas School of Law, where he served as editor-in-chief of the
            Journal of Law & Public Policy, and graduated with Dean's honors in
            2010. After law school, Judge Sandy served as a 1st Assistant Public
            Defender for the State of Minnesota and was a shareholder and
            managing partner at Sandy Law Firm, P.C. He is an active member of
            St. Mary's Catholic Church and started and presides over the
            Dickinson and Clay County Drug Court Programs. When not in the
            Courtroom Judge Sandy enjoys spending time with his family and in
            the woods bow hunting the ever elusive whitetail deer.
          </p>
        </div>
      </div>
    </div>
  )
}

export default About
